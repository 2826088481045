@import '~antd/dist/antd.css';
.ant-card {
  border-radius: 25px;
  margin-top: 25px;
}
.resultCard {
  align-content: center;
  align-items: center;
  align-self: center;
}
.ant-card-bordered {
  border: 4px solid  rgba(220, 220, 220,0.6);
}
.oneCard {
  color: rgba(255, 255, 255, 0.589);
  background-color: rgba(255, 255, 255, 0.616);
}
/** 카드 꼭지점 각도 조절 */
.id {
  box-shadow: 0px 0px 0px gray;
}
.site-page-header {
  background-color: rgba(220, 220, 220);
  text-align: center;
  font-size: large;
}
.ant-upload {
  background-color: skyblue;
}
.App {
  text-align: center;
}
.header {
  background-color: rgba(220, 220, 220);
  display: flex;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: gray;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: gray;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#root {
  height:100%;
}

.ant-page-header-heading-title {
  font-size: xx-large;
}